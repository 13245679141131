import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import { navigationItems } from "./navigationItems"

export default function NavigationSideBar() {
  const [activeNavigationSubcategory, setActiveNavigationSubcategory] =
    useState("")
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const WrapperRef = useRef(null)

  useEffect(() => {
    let collection = document.querySelector(
      ".side-bar-active-item > span:first-of-type"
    )
    collection && setActiveNavigationSubcategory(collection.textContent)
  }, [activeNavigationSubcategory])

  useEffect(() => {
    function handleClickOutside(event) {
      if (WrapperRef.current && !WrapperRef.current.contains(event.target)) {
        setIsMenuOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [WrapperRef])

  return (
    <nav aria-label="Components" className="" ref={WrapperRef}>
      {activeNavigationSubcategory ? (
        <ol className="fixed left-0 z-30 flex items-center w-full gap-2 px-8 py-4 pl-20 list-none shadow-xs bg-white/90 shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 backdrop-blur-sm lg:hidden">
          <li className="flex items-center gap-2">
            <Link
              to="/components"
              className="flex max-w-[20ch] items-center gap-1 truncate whitespace-nowrap text-wuiSlate-700 transition-colors hover:text-wuiEmerald-500 "
            >
              Components
            </Link>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="flex-none w-4 h-4 rotate-180 stroke-wuiSlate-700"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </li>
          <li className="flex-1">
            <a
              href="#"
              className="pointer-events-none flex max-w-[20ch] items-center gap-1 truncate whitespace-nowrap text-wuiSlate-400"
              aria-current="page"
            >
              {activeNavigationSubcategory}
            </a>
          </li>
        </ol>
      ) : (
        <ol className="fixed left-0 z-30 flex items-center w-full gap-2 px-8 py-4 pl-20 list-none shadow-xs bg-white/90 shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 backdrop-blur-sm lg:hidden">
          <li className="flex items-center gap-2">
            <Link
              to="/components"
              className="flex max-w-[20ch] items-center gap-1 truncate whitespace-nowrap text-wuiSlate-700 transition-colors hover:text-wuiEmerald-500 "
            >
              Components
            </Link>
          </li>
        </ol>
      )}

      {/* <!-- Component list --> */}
      {/* <!-- Mobile trigger --> */}
      <button
        className="fixed left-6 top-[6.5rem] z-30 flex cursor-pointer items-center focus:outline-none lg:hidden"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <span className="sr-only">Show menu</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 h-6 r"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
          aria-labelledby="svg-title"
          aria-describedby="svg-description"
          role="graphics-symbol"
        >
          <title id="svg-title">Navigation menu icon</title>
          <desc id="svg-description">
            Menu icon to navigate through side navigation component menu
          </desc>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4 6h16M4 12h16M4 18h7"
          />
        </svg>
      </button>

      {/* <!-- List here  --> */}
      <ul
        className={`fixed bottom-0 right-full top-36 z-30 w-72 overflow-y-auto bg-white py-2 shadow-xl shadow-wuiSlate-200 ring-wuiSlate-200 transition-all   lg:left-0 lg:right-auto lg:top-[5.5rem] lg:translate-x-0 lg:border-none lg:ring-1  ${
          isMenuOpen ? "translate-x-72" : "translate-x-0"
        } `}
      >
        {navigationItems.map((navigationCategory, index) => {
          return (
            <li
              key={index}
              className="pt-2 mt-4 border-t border-wuiSlate-100 first:mt-0 first:border-none first:pt-0"
            >
              <span className="flex px-6 py-4 text-xs font-semibold uppercase text-wuiSlate-900">
                <span className="flex-1 mr-4 truncate">
                  {navigationCategory.navigationCategory}
                </span>
              </span>
              <ul className="text-sm list-none">
                {navigationCategory.navigationSubCategories.map(
                  (navigationSubcategory, index) => {
                    return (
                      <li key={index}>
                        <Link
                          id={`${navigationSubcategory.subCategory}`}
                          to={navigationSubcategory.subCategoryLink}
                          partiallyActive={true}
                          activeClassName="side-bar-active-item after:absolute after:w-1 after:inset-y-2 after:left-0 after:bg-wuiEmerald-400 text-wuiEmerald-500 bg-wuiEmerald-50 "
                          className={`relative flex items-center px-6 py-3 pl-8 hover:bg-wuiEmerald-50  hover:text-wuiEmerald-500 focus:bg-wuiEmerald-50 focus:text-wuiEmerald-700 ${
                            navigationSubcategory.comingSoon
                              ? " pointer-events-none text-wuiSlate-500/60"
                              : ""
                          }`}
                          onClick={() =>
                            setActiveNavigationSubcategory(
                              navigationSubcategory.subCategory
                            )
                          }
                        >
                          <span className="flex-1 mr-4 truncate ">
                            {navigationSubcategory.subCategory}
                          </span>

                          {navigationSubcategory.comingSoon && (
                            <div className="px-2 py-1 text-xs rounded-md bg-wuiAmber-50 text-wuiAmber-500">
                              coming next
                            </div>
                          )}

                          {navigationSubcategory.new && (
                            <div className="px-2 py-1 text-xs rounded-md bg-wuiEmerald-100 text-wuiEmerald-500">
                              new
                            </div>
                          )}

                          {navigationSubcategory.updated && (
                            <div className="px-2 py-1 text-xs rounded-md bg-wuiCyan-50 text-wuiCyan-500">
                              updated
                            </div>
                          )}
                        </Link>
                      </li>
                    )
                  }
                )}
              </ul>
            </li>
          )
        })}
        <li>
          <a
            href="mailto:info@wind-ui.com?subject=Suggestion for WindUI"
            aria-label="Send Suggestions for WindUI Components link"
            className="relative mb-16 mt-6 block items-center px-5 hover:text-wuiEmerald-500 focus:text-wuiEmerald-700 [&:hover>span]:border-emerald-500 [&:hover>span]:bg-emerald-50"
            target="_blank"
          >
            <span className="relative z-10 flex items-center justify-between gap-2 rounded border border-dashed border-slate-300 px-3 py-2.5 text-sm transition-colors duration-300">
              Didn't find it? Ask us for it!
              <svg
                viewBox="0 0 24 24"
                height={16}
                width={16}
                xmlns="http://www.w3.org/2000/svg"
                strokeWidth={1.5}
              >
                <path
                  d="M2.759,15.629a1.664,1.664,0,0,1-.882-3.075L20.36,1A1.663,1.663,0,0,1,22.876,2.72l-3.6,19.173a1.664,1.664,0,0,1-2.966.691L11.1,15.629Z"
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.1,15.629H8.6V20.8a1.663,1.663,0,0,0,2.6,1.374l3.178-2.166Z"
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.099 15.629L22.179 1.039"
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </a>
        </li>
      </ul>
      <div className="fixed bottom-0 left-0 z-[31] hidden h-16 w-72 bg-gradient-to-t from-white to-transparent lg:block"></div>
    </nav>
  )
}
