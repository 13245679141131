import React from "react"
import { Link } from "gatsby"

import SidebarLayout from "../layouts/SidebarLayout"
import Seo from "../components/seo"

//Images
import { navigationItems } from "../components/navigation/navigationItems"

export default function Components() {
  return (
    <SidebarLayout>
      <Seo
        title="Wind UI - Tailwind CSS Components HTML/React"
        ogtitle="Wind UI  - Tailwind CSS Components HTML/React"
        description="Tailwind CSS Components Library - Streamline your Tailwind CSS projects with expertly made, responsive and accessible free UI Components in React and HTML."
        ogdescription="Tailwind CSS Components Library - Streamline your Tailwind CSS projects with expertly made, responsive and accessible free UI Components in React and HTML."
        author="Wind UI"
        url="components/"
        keywords="Tailwind, TailwindUI TailwindCSS, UI Component library, HTML, React, React JS, Avatars, Badges, Buttons, Breadcrumbs, Checkbox, Input, Pagination, Radio, Select, Tables, Tabs, Textarea, Tooltips, Toogle, Modal, Alerts, Carousel, Collapse, Progress, Range, Rating, Menu, Navbar, Tabs
        "
        robots="index, follow"
        revisit_after="7 days"
      />

      <section className="w-full pb-16 lg:pb-20">
        <header className="flex items-center">
          <div className="max-w-2xl m-auto">
            <h1 className="flex flex-col items-center justify-center pb-6 text-4xl font-semibold leading-tight text-center text-wuiSlate-900 md:text-5xl lg:text-6xl">
              Component Library
            </h1>
            <p className="pb-6 text-lg text-center">
              Explore the whole collection of responsive, accessible components
              built with React and HTML ready to be used on your website or app.
            </p>
          </div>
        </header>
        <div className="w-full">
          <div className="flex flex-col items-start gap-6">
            {navigationItems.map((navigationSubcategory, index) => {
              return (
                <div className="grid items-center grid-cols-4 gap-6 py-6 lg:grid-cols-12">
                  <h3 className="col-span-4 text-lg font-medium text-wuiSlate-900 lg:col-span-12">
                    {navigationSubcategory.navigationCategory}
                  </h3>
                  {navigationSubcategory.navigationSubCategories.map(
                    (navigationSubcategory, index) => {
                      return (
                        <Link
                          to={navigationSubcategory.subCategoryLink}
                          partiallyActive={true}
                          className={`col-span-4  overflow-hidden rounded-lg bg-white shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 transition-shadow duration-500 hover:shadow-2xl hover:shadow-wuiSlate-300 md:col-span-2 lg:col-span-4 xl:col-span-3 ${
                            navigationSubcategory.comingSoon
                              ? "cursor-no-drop shadow-md shadow-wuiSlate-200 hover:shadow-md hover:shadow-wuiSlate-200"
                              : ""
                          }`}
                          key={index}
                        >
                          <header className="flex flex-col gap-4 px-6 py-4">
                            <h2 className="flex items-center justify-between text-base font-medium text-wuiSlate-700 lg:text-lg">
                              {navigationSubcategory.subCategory}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-5 h-5"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                                />
                              </svg>
                            </h2>
                          </header>
                          <figure>
                            <img
                              src={navigationSubcategory.subCategoryImage}
                              alt="card image"
                              className="mx-auto aspect-[4/3]"
                            />
                          </figure>
                          <footer className="flex flex-col gap-4 px-6 py-4">
                            <div className="flex items-center justify-between">
                              {navigationSubcategory.subCategoryVarNumber && (
                                <h3 className="text-xs font-medium leading-5 uppercase text-wuiSlate-400">
                                  {navigationSubcategory.subCategoryVarNumber}{" "}
                                  variations
                                </h3>
                              )}
                              {navigationSubcategory.comingSoon && (
                                <div className="px-2 py-1 ml-auto text-xs uppercase rounded-md bg-wuiAmber-50 text-wuiAmber-500">
                                  coming next
                                </div>
                              )}

                              {navigationSubcategory.new && (
                                <div className="px-2 py-1 text-xs uppercase rounded-md bg-wuiEmerald-100 text-wuiEmerald-500">
                                  new
                                </div>
                              )}

                              {navigationSubcategory.updated && (
                                <div className="px-2 py-1 text-xs uppercase rounded-md bg-wuiCyan-50 text-wuiCyan-500">
                                  updated
                                </div>
                              )}
                            </div>
                          </footer>
                        </Link>
                      )
                    }
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </section>
    </SidebarLayout>
  )
}
