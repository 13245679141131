import { useState, useEffect } from "react"

import { useCookies } from "react-cookie"

function useTheme() {
  const [currentTheme, setCurrentTheme] = useState("emerald")

  const [cookies, setCookie] = useCookies(["themeColor"])

  useEffect(() => {
    if (cookies.themeColor) {
      setCurrentTheme(cookies.themeColor)
      let toReplace = document.querySelectorAll(`[class*=${currentTheme}-]`)

      let re = new RegExp(`\\b${currentTheme}\\b`, "g")

      for (var i = 0; i < toReplace.length; i++) {
        let classToReplace = toReplace[i].className

        if (typeof classToReplace !== "object") {
          toReplace[i].className = classToReplace
            .toString()
            .replace(re, cookies.themeColor)
        } else {
          toReplace[i].className.baseVal = classToReplace.baseVal
            .toString()
            .replace(re, cookies.themeColor)
        }
      }
    } else {
      setCookie("themeColor", "emerald", { path: "/" })
    }
  })

  return {
    currentTheme,
  }
}

export default useTheme
