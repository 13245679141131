import React, { useEffect } from "react"
import { CookiesProvider } from "react-cookie"
// Theme
import useTheme from "../hooks/useTheme"
// Components
import Header from "../components/header"
import NavigationSideBar from "../components/navigation"
import Footer from "../components/footer"
import Background from "../components/background"
import Notification from "../components/notification"

export default function SidebarLayout(props) {
  useEffect(() => {
    var root = document.getElementsByTagName("html")[0]
    root.setAttribute(
      "class",
      "[&_*:focus-visible]:outline-none scroll-smooth scroll-pt-24 bg-wuiSlate-50 text-wuiSlate-500 selection:bg-wuiEmerald-500 selection:text-wuiEmerald-50"
    )
  }, [])

  useTheme()

  return (
    <CookiesProvider>
      <Header showTheme fullWidth />
      <main>
        <div className="max-w-full pl-0 lg:flex">
          {/* <!-- Component Navigation --> */}
          <NavigationSideBar />

          {/* <!-- Component content --> */}
          <article className="flex min-w-0 flex-1 flex-col gap-8 pt-20 lg:ml-72">
            <div className="container flex gap-6">{props.children}</div>
            <Footer footerType="fullWidth" />
          </article>
        </div>
      </main>

      <Background />
      <Notification />
    </CookiesProvider>
  )
}
